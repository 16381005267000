<template>
  <div>
    <q-card flat bordered class="q-ma-sm">
      <q-card-section>
        <q-form class="q-pa-md row q-col-gutter-md" @submit.prevent="listConversions">
          <div class="col-6">
            <select-request
              v-model="filter.campaign_id"
              :label="$t('campaign')"
              @update="(value) => (filter.campaign_id = value.id)"
              outlined
              endpoint="/redirect?app=ADVERTISER&path=/api/get/campaign/all"
              option-label="name"
              option-value="campaign_id"
              clearable
              :rules="[isRequired]"
            />
          </div>
          <div class="col-6">
            <q-input outlined :rules="[isRequired]" readonly v-model="filter.period" mask="##/####" fill-mask="##/####">
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date
                      minimal
                      clearable
                      mask="MM/YYYY"
                      emit-immediately
                      v-model="filter.period"
                      default-view="Years"
                      @input="controllerDates"
                      :options="[{ view: ['Years', 'Months'] }]"
                    >
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="col-6">
            <q-select
              outlined
              v-model="filter.status_v3"
              clearable
              :options="[
                { label: 'Aprovado', value: 1 },
                { label: 'Recusado', value: 0 },
                { label: 'Pendente', value: 2 },
                { label: 'Não encontrado', value: -1 }
              ]"
              label="Status V3"
            />
          </div>
          <div class="col-6">
            <q-select
              outlined
              v-model="filter.status_adv"
              clearable
              option-value="value"
              option-label="label"
              :options="[
                { label: 'Aprovado', value: 1 },
                { label: 'Recusado', value: 0 },
                { label: 'Não encontrado', value: -1 }
              ]"
              label="Status Anunciante"
            />
          </div>
          <div class="col-12 text-right">
            <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" />
            <q-btn color="positive" type="sumit" :label="$t('search')" no-caps unelevated class="q-px-md" />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import ConversorPhpService from '@/services/ConversorPhpService.js';
import AdvertiserValidationMixin from '@/mixins/AdvertiserValidationMixin.js';
export default {
  name: 'HeaderApproveValidations',
  components: { SelectRequest },
  mixins: [ConversorPhpService, AdvertiserValidationMixin],
  data() {
    return {};
  },

  methods: {
    async listConversions() {
      this.onLoading(true);
      try {
        let filterString = [];
        for (const key in this.filter) {
          if (this.filter[key]) {
            filterString.push(
              `${key}%3D${
                key == 'period'
                  ? this.dateParse(this.filter[key])
                  : key.includes('status')
                  ? this.filter[key].value
                  : this.filter[key]
              }`
            );
          }
        }
        const { data, status } = await this.getAdvertiserValidations(filterString.join('%26'));
        if (status == 200) {
          this.ActionSetAdvertiserValidationConversions(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    controllerDates() {
      this.countclickedDate += 1;
      if (this.countclickedDate >= 2) {
        this.countclickedDate = 0;
        this.$refs.qDateProxy.hide();
      }
    }
  }
};
</script>

<style></style>
