<template>
  <div>
    <q-table
      flat
      bordered
      :data="advertiserValidationConversions"
      :columns="columns"
      grid-header
      no-data-label="Sem validações"
    >
      <template v-slot:body-cell="props">
        <q-td :class="props.row.revenue && props.row.revenue != props.row.price ? 'bg-red-4' : ''">
          {{ props.row[props.col.field] }}</q-td
        >
      </template>
      <template v-slot:top-left>
        <q-btn
          color="positive"
          @click="onSubmitApprove"
          type="sumit"
          label="Aprovar"
          :disabled="selectedConversions.length == 0"
          no-caps
          unelevated
          class="q-px-md q-mr-md"
        />
        <q-btn
          color="primary"
          @click="showResume"
          label="Ver Resumo"
          :disabled="advertiserValidationConversions.length == 0"
          no-caps
          unelevated
          class="q-px-md"
        />
      </template>
      <template v-slot:body-cell-select="props">
        <q-td :props="props">
          <q-checkbox v-model="selectedConversions" :val="props.row" />
        </q-td>
      </template>
    </q-table>
    <q-dialog v-model="showDialogApproveDivergentConversions">
      <CardApproveDivergentConversions @confirmApprove="confirmApprove" />
    </q-dialog>
  </div>
</template>

<script>
import AdvertiserValidationMixin from '@/mixins/AdvertiserValidationMixin.js';
import CardApproveDivergentConversions from '@/components/campaign/Validation/CardApproveDivergentConversions';
export default {
  name: 'FormApproveValidation',
  mixins: [AdvertiserValidationMixin],
  components: { CardApproveDivergentConversions },
  data() {
    return {
      showDialogApproveDivergentConversions: false,
      selectedConversions: [],
      columns: [
        {
          name: 'select',
          field: 'select',
          label: 'Selecionar',
          align: 'left'
        },
        {
          name: 'campaign_name',
          field: 'campaign_name',
          required: true,
          label: 'Campanha',
          align: 'left',
          format: (val) => `${val}`
        },
        {
          name: 'site_name',
          field: 'site_name',
          label: 'Canal',
          align: 'left'
        },
        {
          name: 'transaction_id',
          field: 'transaction_id',
          label: 'ID de Conversão',
          align: 'left'
        },
        {
          name: 'tra_id',
          field: 'tra_id',
          label: 'Tra ID',
          align: 'left'
        },
        {
          name: 'revenue',
          field: 'revenue',
          label: 'Valor da Conversão (Anunciante)',
          align: 'center'
        },
        {
          name: 'price',
          field: 'price',
          label: 'Valor da Conversão (V3)',
          align: 'center'
        },
        {
          name: 'comission_full',
          field: 'comission_full',
          label: 'Comissão',
          align: 'left'
        },
        {
          name: 'comission_site',
          field: 'comission_site',
          label: 'Custo',
          align: 'left'
        },
        {
          name: 'comission_afilio',
          field: 'comission_afilio',
          label: 'Margem',
          align: 'left'
        },
        {
          name: 'transaction_date',
          field: 'transaction_date',
          label: 'Data/Hora Conversão',
          align: 'left'
        },
        {
          name: 'status_v3',
          field: 'status_v3',
          label: 'Status V3',
          align: 'left'
        },
        {
          name: 'status_adv',
          field: 'status_adv',
          label: 'Status Cliente',
          align: 'left'
        }
      ]
    };
  },
  methods: {
    async confirmApprove() {
      await this.approve();
      this.showDialogApproveDivergentConversions = false;
    },

    async onSubmitApprove() {
      if (this.selectedConversions.some((value) => value.revenue && value.revenue != value.price)) {
        this.showDialogApproveDivergentConversions = true;
        return;
      }

      this.approve();
    },

    async approve() {
      try {
        this.onLoading(true);
        const { data, status } = await this.approveValidations(
          this.selectedConversions.map((val) => val.transaction_id).join(',')
        );
        console.log(data, status);
        if (status == 201) {
          this.successNotify('Validações aprovadas com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async showResume() {
      try {
        this.onLoading(true);
        const { data, status } = await this.getValidationResume();
        if (status == 200) {
          this.ActionSetCampaignValidationHistory(data);
          this.$emit('showCardValidationHistory');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style></style>
