<template>
  <q-card
    flat
    bordered
    class="q-pa-md q-mt-md"
    v-if="campaignValidationHistory && Object.keys(campaignValidationHistory).length > 0"
    style="background-color: #f3e5f5"
  >
    <div class="row q-pt-md q-gutter-x-sm">
      <div class="col-4">
        <p>
          <b>ID Campanha:</b> <span>{{ campaignValidationHistory.campaign_id }}</span>
        </p>
        <p>
          <b>Comissão Full:</b>
          <span>
            {{
              campaignValidationHistory.comissao_full
                ? parseFloat(campaignValidationHistory.comissao_full).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                : null
            }}</span
          >
        </p>
        <p>
          <b>Comissão Full Aprovada:</b>
          <span>
            {{
              campaignValidationHistory.comissao_full_aprovada
                ? parseFloat(campaignValidationHistory.comissao_full_aprovada).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                : null
            }}</span
          >
        </p>
        <p>
          <b>Margem:</b>
          <span>
            {{
              campaignValidationHistory.margem
                ? parseFloat(campaignValidationHistory.margem).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                : null
            }}</span
          >
        </p>
        <p>
          <b>Margem Aprovada:</b>
          <span>
            {{
              campaignValidationHistory.margem_aprovada
                ? parseFloat(campaignValidationHistory.margem_aprovada).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  })
                : null
            }}</span
          >
        </p>
      </div>
      <div class="col-5">
        <p>
          <b>Status:</b> <span>{{ campaignValidationHistory.status }}</span>
        </p>
        <p>
          <b
            >Taxa de captação:<q-icon name="mdi-help-circle-outline" size="1.1em"
              ><q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Conversão total aprovada / Conversão total captada <br />(Em caso de validação concluída)
              </q-tooltip></q-icon
            ></b
          >
          <span>&nbsp;{{ campaignValidationHistory.taxa_captacao.toFixed(2) }} %</span>
        </p>
        <p>
          <b
            >Taxa de validação:<q-icon name="mdi-help-circle-outline" size="1.1em"
              ><q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Comissão full aprovada / Comissão full captada <br />(Em caso de validação concluída)
              </q-tooltip></q-icon
            ></b
          >
          <span>&nbsp;{{ campaignValidationHistory.taxa_validacao.toFixed(2) }} %</span>
        </p>
        <p>
          <b
            >Comparativo de Comissão Full:<q-icon name="mdi-help-circle-outline" size="1.1em"
              ><q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Comparativo com mês anterior de comissão full validada<br />(Comissão full mês atual / Comissão full mês
                anterior)
              </q-tooltip></q-icon
            ></b
          >
          <span>&nbsp;R$ {{ campaignValidationHistory.comissao_mes_anterior }}</span>
        </p>
        <p>
          <b>Comparativo de margem validada:</b> <span>R$ {{ campaignValidationHistory.margem_mes_anterior }}</span>
        </p>
      </div>
      <div class="col-2">
        <p>
          <b>Total:</b>
          <span> {{ campaignValidationHistory.total }}</span>
        </p>
        <p>
          <b>Pendentes:</b> <span>{{ campaignValidationHistory.pendentes }}</span>
        </p>
        <p>
          <b>Aprovadas:</b> <span>{{ campaignValidationHistory.aprovadas }}</span>
        </p>
        <p>
          <b>Recusadas:</b> <span>{{ campaignValidationHistory.recusadas }}</span>
        </p>
      </div>
    </div>
  </q-card>
</template>

<script>
export default {
  name: 'CardValidationHistory',
  props: {
    campaignValidationHistory: { type: Object, default: null }
  }
};
</script>

<style></style>
