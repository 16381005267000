var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('q-card',{staticClass:"q-ma-sm",attrs:{"flat":"","bordered":""}},[_c('q-card-section',[_c('q-form',{staticClass:"q-pa-md row q-col-gutter-md",on:{"submit":function($event){$event.preventDefault();return _vm.listConversions.apply(null, arguments)}}},[_c('div',{staticClass:"col-6"},[_c('select-request',{attrs:{"label":_vm.$t('campaign'),"outlined":"","endpoint":"/redirect?app=ADVERTISER&path=/api/get/campaign/all","option-label":"name","option-value":"campaign_id","clearable":"","rules":[_vm.isRequired]},on:{"update":(value) => (_vm.filter.campaign_id = value.id)},model:{value:(_vm.filter.campaign_id),callback:function ($$v) {_vm.$set(_vm.filter, "campaign_id", $$v)},expression:"filter.campaign_id"}})],1),_c('div',{staticClass:"col-6"},[_c('q-input',{attrs:{"outlined":"","rules":[_vm.isRequired],"readonly":"","mask":"##/####","fill-mask":"##/####"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"event"}},[_c('q-popup-proxy',{ref:"qDateProxy",attrs:{"transition-show":"scale","transition-hide":"scale"}},[_c('q-date',{attrs:{"minimal":"","clearable":"","mask":"MM/YYYY","emit-immediately":"","default-view":"Years","options":[{ view: ['Years', 'Months'] }]},on:{"input":_vm.controllerDates},model:{value:(_vm.filter.period),callback:function ($$v) {_vm.$set(_vm.filter, "period", $$v)},expression:"filter.period"}})],1)],1)]},proxy:true}]),model:{value:(_vm.filter.period),callback:function ($$v) {_vm.$set(_vm.filter, "period", $$v)},expression:"filter.period"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"outlined":"","clearable":"","options":[
              { label: 'Aprovado', value: 1 },
              { label: 'Recusado', value: 0 },
              { label: 'Pendente', value: 2 },
              { label: 'Não encontrado', value: -1 }
            ],"label":"Status V3"},model:{value:(_vm.filter.status_v3),callback:function ($$v) {_vm.$set(_vm.filter, "status_v3", $$v)},expression:"filter.status_v3"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"outlined":"","clearable":"","option-value":"value","option-label":"label","options":[
              { label: 'Aprovado', value: 1 },
              { label: 'Recusado', value: 0 },
              { label: 'Não encontrado', value: -1 }
            ],"label":"Status Anunciante"},model:{value:(_vm.filter.status_adv),callback:function ($$v) {_vm.$set(_vm.filter, "status_adv", $$v)},expression:"filter.status_adv"}})],1),_c('div',{staticClass:"col-12 text-right"},[_c('q-btn',{staticClass:"q-px-md q-mx-md",attrs:{"color":"primary","label":_vm.$t('clear'),"no-caps":"","outline":""}}),_c('q-btn',{staticClass:"q-px-md",attrs:{"color":"positive","type":"sumit","label":_vm.$t('search'),"no-caps":"","unelevated":""}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }