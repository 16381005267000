<template>
  <div>
    <q-toolbar class="q-pr-none q-mb-md justify-end">
      <!-- <q-input outlined v-model="search" dense type="text" label="Pesquisar">
               <template v-slot:prepend>
                 <q-icon name="eva-search-outline" />
               </template>
             </q-input> -->
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> {{ $t('filter_report') }}</q-tooltip>
      </q-btn>
      <!-- <q-btn
                 outline
                 color="primary"
                 icon-right="archive"
                 class="q-mr-sm"
                 no-caps
                 @click="
           exportTableCsv(
             prepareItemCSV(reports),
             prepareColumnCSV(columns),
             'reports'
           )
         "
                 unelevated
             >
                 <q-tooltip> {{ $t("export_csv") }}</q-tooltip>
             </q-btn> -->
    </q-toolbar>

    {{ csvList }}

    <q-banner class="bg-purple text-white q-ma-sm" rounded>
      <q-item>
        <q-item-section top avatar>
          <q-icon name="mdi-information-outline" size="lg" />
        </q-item-section>
        <q-item-section>
          <q-item-label caption lines="3" class="text-white">
            <strong>Atenção ao formato correto do arquivo! </strong>O arquivo deverá conter apenas a seguinte coluna:
            "Id Conversão".<br />
            Em caso de dúvidas, você pode fazer o download do nosso modelo clicando
            <a class="link" href="https://afilio-v3.s3.amazonaws.com/template_validacao_conversao.csv">aqui</a>.
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-banner>

    <q-intersection transition="slide-down" v-show="filter">
      <q-card flat v-if="filter" bordered class="q-ma-sm">
        <q-toolbar>
          <!-- <q-toolbar-title> {{ $t("filter") }}</q-toolbar-title> -->
          <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
        </q-toolbar>
        <q-card-section class="q-pt-none row q-col-gutter-md">
          <div class="col-6">
            <q-select
              v-model="params.campaignId"
              :options="campaignFiltered"
              option-label="name"
              option-value="id"
              :label="$t('campaign')"
              outlined
              clearable
              clear-icon="mdi-close"
              input-debounce="200"
              use-input
              @filter="filterCampaign"
            />
          </div>
          <div class="col-6">
            <div class="row q-col-gutter-sm">
              <div class="col">
                <q-select
                  v-model="params.status"
                  :options="[
                    {
                      label: $t('approved'),
                      value: 2
                    },
                    {
                      label: $t('pending'),
                      value: 1
                    },
                    {
                      label: $t('refused'),
                      value: 0
                    }
                  ]"
                  label="Alterar status para"
                  outlined
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <!-- <date-input
                           outlined
                           label="Filtrar por mês"
                           v-model="params.date"
                           @update="(value) => (params.date = value)"
                           clearable
                         /> -->
            <q-input outlined v-model="params.date" mask="##/####" fill-mask="##/####">
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                    <q-date
                      minimal
                      mask="MM/YYYY"
                      emit-immediately
                      v-model="params.date"
                      default-view="Years"
                      @input="controllerDates"
                      :options="[{ view: ['Years', 'Months'] }]"
                    >
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
          <div class="col-6" @keyup.enter="validadeFormFilterReports">
            <q-file outlined v-model="params.csvFile" accept=".csv" @input="changeCSV" label="Arquivo (CSV)">
              <template v-slot:prepend>
                <q-icon name="attach_file" />
              </template>
            </q-file>
          </div>
          <div class="col-12 text-right">
            <q-btn color="primary" :label="$t('clear')" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
            <q-btn
              color="positive"
              :label="$t('search')"
              no-caps
              unelevated
              class="q-px-md"
              @click="validadeFormFilterReports"
            />
          </div>
        </q-card-section>
      </q-card>
    </q-intersection>

    <!-- <q-table
           :data="data"
           :columns="columns"
         /> -->
  </div>
</template>

<script>
import ReportMixin from '../../../mixins/ReportMixin';

// import DateInput from "../../widgets/inputsGlobal/DateInput.vue";
export default {
  name: 'HeaderConversionReports',
  mixins: [ReportMixin],

  props: { propsClearFilter: Boolean },
  watch: {
    propsClearFilter() {
      if (this.propsClearFilter) this.clearFilter();
      this.$emit('clearFilter');
    },

    'params.status': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.params.campaignId && this.params.date && this.params.csvFile) {
          this.validadeFormFilterReports();
        }
      },
      deep: true
    }
  },

  data() {
    return {
      campaignFiltered: [],
      campaignList: [],
      filter: true,
      countclickedDate: 0,
      csvList: null,
      params: {
        campaignId: null,
        status: null,
        date: '',
        csvFile: null
      },
      columnsLocal: [],
      data: [],
      dataPut: []
    };
  },

  created() {
    this.getCampaigns();
  },

  methods: {
    filterCampaign(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.campaignFiltered = this.campaignList;
        this.campaignFiltered = this.campaignList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    async getCampaigns() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=ADVERTISER&path=/api/get/campaign');
        if (status === 200) this.campaignList = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    changeCSV(e) {
      let readCsv = new FileReader();
      readCsv.readAsText(e);

      readCsv.onload = () => {
        let csvList = readCsv.result.split('\n');
        let columnsLocal = csvList[0].split(';');

        columnsLocal.forEach((element) => {
          this.columnsLocal.push({
            name: element.replace(/\r/g, ''),
            label: element.replace(/\r/g, ''),
            field: element.replace(/\r/g, ''),
            align: 'left'
          });
        });

        let dataFormat = csvList.filter((item) => item !== csvList[0]);

        dataFormat = dataFormat
          .map((item) => item.replace(' ', '').replace(';;\r', '').replace('\n', ''))
          .filter((item) => item != '');

        this.data = dataFormat;
      };
    },

    controllerDates() {
      this.countclickedDate += 1;
      if (this.countclickedDate >= 2) {
        this.countclickedDate = 0;
        this.$refs.qDateProxy.hide();
      }
    },

    clearFilter() {
      this.params = {
        campaignId: null,
        date: '',
        status: null,
        csvFile: null
      };

      this.$emit('generateTable', {});
    },

    async validadeFormFilterReports() {
      if (!this.params.campaignId) {
        this.errorNotify('É necessário escolher uma campanha.');
        return;
      }
      if (!this.params.date) {
        this.errorNotify('É necessário escolher uma data.');
        return;
      }
      if (!this.params.status) {
        this.errorNotify('É necessário escolher um status.');
        return;
      }
      if (!this.params.csvFile) {
        this.errorNotify('É necessário enviar um arquivo CSV.');
        return;
      }

      this.onLoading(true);
      try {
        const { data, status } = await this.$http.put(`/v2/redirect`, {
          app: `conversorphp`,
          path: `/api/previewValidateConversion/${this.params.campaignId.id}`,
          params: {
            data: {
              status: this.params.status.value,
              periodo: this.dateParse(this.params.date),
              user_id: this.user.user_id,
              json: this.data.join()
            }
          }
        });

        if (status === 200) this.dataPut = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }

      let params = {
        campaignId: this.params.campaignId,
        date: this.params.date,
        status: this.params.status
      };

      let dataParse = this.data.filter((item) => item != '');

      let event = [dataParse, this.columnsLocal, params, this.dataPut];

      this.$emit('generateTable', event);
    },

    dateParse(date) {
      let dateArray = date.split('/');
      return `${dateArray[1]}-${dateArray[0]}-1`;
    },

    onFilterReports() {
      let filterSelect = [];

      if (this.params.campaignId) filterSelect.push(`campaign_id=${this.params.campaignId}`);
      if (this.params.date) {
        if (this.params.date.split('/').length == 3) {
          let from = this.params.date.split('/')[0];
          let to = this.params.date.split('/')[2];

          this.params.date = {
            from: this.$moment(this.stringToDate(from, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD'),
            to: this.$moment(this.stringToDate(to, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD')
          };

          filterSelect.push(`start_date=${this.params.date.from}`);
          filterSelect.push(`end_date=${this.params.date.to}`);
        }
      }
      if (this.params.status) filterSelect.push(`status=${this.params.status.value}`);
    }
  }
};
</script>

<style>
.link {
  color: white;
}
</style>
