<template>
  <q-card style="min-width: 500px" flat bordered class="q-pa-md row justify-end">
    <q-card-section class="text-h5 col-12">Confirme a Aprovação: </q-card-section>

    <q-card-section
      >Algumas das conversões selecionadas possuem <b>valores divergentes</b> entre a validação V3 e Advertiser. Por
      favor, lembre-se de realizar um ajuste de comissão ou gerar um bônus para acertar os valores
      validados.</q-card-section
    >

    <q-card-section class="">
      <q-btn
        color="positive"
        @click="$emit('confirmApprove')"
        type="sumit"
        label="Aprovar"
        no-caps
        unelevated
        class="q-px-md q-mr-md"
      />
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: 'CardApproveDivergentConversions'
};
</script>

<style></style>
