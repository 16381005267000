import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    methods: {
        ...mapActions('AdvertiserValidation', [
            'ActionSetAdvertiserValidationConversions',
            'ActionSetCampaignValidationHistory'
        ]),
        async approveValidations(selectedConversions) {
            return await this.$http.put('/v2/redirect', {
                app: 'CONVERSORPHP',
                path: `/api/validateConversion/${this.filter.campaign_id}`,
                params: {
                    data: {
                        user_id: this.user.user_id,
                        periodo: this.filter.period,
                        json: selectedConversions,
                        status: 2
                    }
                }
            });
        },

        dateParse(date) {
            let dateArray = date.split('/');
            return `${dateArray[1]}-${dateArray[0]}-1`;
        },
        async getValidationResume() {
            return await this.$http.get(
                `/v2/redirect?app=REPORT&path=/api/transaction/history%3Fcampaign_id%3D${this.filter.campaign_id
                }%26periodo%3D${this.dateParse(this.filter.period)}`
            );
        }
    },
    computed: {
        ...mapGetters('AdvertiserValidation', ['advertiserValidationConversions', 'campaignValidationHistory']),
        ...mapState('AdvertiserValidation', ['filter'])
    }
};
