<template>
  <q-page>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Validar Conversões </q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Validar Conversões" exact />
      </q-breadcrumbs>
    </div>

    <q-tabs
      v-model="tab"
      dense
      className="text-grey"
      active-color="primary"
      indicator-color="primary"
      align="justify"
      narrow-indicator
    >
      <q-tab name="validateConversions" label="Validar Conversões" />
      <q-tab name="approveValidations" label="Aprovar Validações" />
    </q-tabs>

    <q-tab-panels v-model="tab">
      <q-tab-panel name="validateConversions">
        <header-validate-conversions
          @generateTable="generateTable"
          :propsClearFilter="clearFilter"
          @clearFilter="clearFilter = false"
        />
        <form-validate-conversions v-if="validadeFilter" :dataFormCSV="dataFormCSV" @clearFilter="clearFilter = true" />
      </q-tab-panel>
      <q-tab-panel name="approveValidations">
        <q-dialog v-model="showCardCampaignValidationHistory">
          <card-validation-history :campaignValidationHistory="campaignValidationHistory" />
        </q-dialog>
        <header-approve-validation />
        <form-approve-validation @showCardValidationHistory="showCardCampaignValidationHistory = true" />
      </q-tab-panel>
    </q-tab-panels>
  </q-page>
</template>

<script>
import HeaderValidateConversions from '@/components/campaign/Validation/HeaderValidateConversions.vue';
import FormValidateConversions from '@/components/campaign/Validation/FormValidateConversions.vue';
import HeaderApproveValidation from '@/components/campaign/Validation/HeaderApproveValidation.vue';
import FormApproveValidation from '@/components/campaign/Validation/FormApproveValidation.vue';
import CardValidationHistory from '@/components/campaign/Validation/CardValidationHistory.vue';
import AdvertiserValidationMixin from '@/mixins/AdvertiserValidationMixin.js';

export default {
  components: {
    HeaderValidateConversions,
    FormValidateConversions,
    HeaderApproveValidation,
    FormApproveValidation,
    CardValidationHistory
  },
  mixins: [AdvertiserValidationMixin],
  name: 'PageIncludeSites',
  data() {
    return {
      validadeFilter: false,
      dataFormCSV: null,
      dataTable: [],
      clearFilter: false,
      tab: 'validateConversions',
      showCardCampaignValidationHistory: false
    };
  },
  methods: {
    generateTable(e) {
      this.validadeFilter = true;
      this.dataFormCSV = e;
    }
  }
};
</script>
